import React, { useEffect, useState, useRef } from "react"
import { Link, graphql } from "gatsby";
import {
  Layout, SEO, CBreadCrumb, LWrap,CInterviewJumbotron, CQuoteBox, CInterviewMedia, CSectTitle, CSchedule, CStars, CLabelCard, CBtnList
} from "../../../components/_index"
import { recruitArea } from "../../../utils/recruit"

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter

  return (
    <Layout isKv={false}>
      <SEO title={frontmatter?.title}
        description={frontmatter?.description}
      />
      <CBreadCrumb data={{
        parent: [
          {
            label: '採用情報',
            path: '/recruit/'
          },
        ],
        current: {
          label: frontmatter?.title.includes('-') ? frontmatter?.title.split('-')[0] : frontmatter?.title
        }
      }} />
      <CInterviewJumbotron data={{
        date: '2022年02月14日',
        subTitle: '管理課',
        title: '橋本 由衣',
        labels: ['大阪・神戸エリア'],
        img: {
          src: '/assets/images/recruit/article02/kv.png'
        },
        imgSp: {
          src: '/assets/images/recruit/article02/kv__sp.png'
        }
      }} />
      <div className="l_sect u_pt80">
        <LWrap>
          <CQuoteBox>
            <p>2020年に入社後、ザ ロイヤルパークホテル アイコニック 大阪御堂筋にフロントスタッフとして配属。<br />2021年3月より、同ホテルを管理する大阪・神戸エリア管理課に異動し、現在に至る。</p>
          </CQuoteBox>
          <h2 className="c_headingLv2">社員インタビュー</h2>
          <CInterviewMedia data={[
            {
              img: {
                src: '/assets/images/recruit/article02/img_interview.png',
                alt: ''
              },
              title: <>
               <span>ホテル業界を目指したきっかけや</span>
               <span>入社を決めた理由はなんですか？</span>
              </>,
              text: <>
                <p>高校生の時にアルバイトしていたピザ店で、自分の勧めた商品を気に入ってもらえたことがきっかけです。電話越しで声のみの接客でしたが、お客様に喜んでいただけたのが嬉しくて、気づけばさまざまな接客業でアルバイトをしていました。</p>
                <p>大学時代には、ホテルのアルバイトも経験し、その時の先輩からロイヤルパークホテルズの良さを教えてもらって、採用試験に臨みました。正直、「何となく」の応募からスタートし、既に就職活動は終了しつつあったものの、企業研究を重ねるうちに、地元大阪にも新しくホテルが開業することを知りました。さらには有名小説とその映画の舞台になったホテルのグループホテルであることも私の中で決め手になりました。</p>
                <p>内定をいただいた後も、まだ開業していないホテルへの不安は一切なく、ワクワクした気持ちしかなかったですね。</p>
              </>,
            },
            {
              img: {
                src: '/assets/images/recruit/article02/img_interview02.png',
                alt: ''
              },
              title: <>
               <span>入社後、実際に働いてみて感じたことは</span>
               <span>ありましたか？</span>
              </>,
              text: <>
                <p>開業して間もない新しいホテルに配属されましたが、コロナ禍でお客様が少なく、もどかしさを感じました。少ないとはいえ、ホテルにお越しいただく客層もニーズもさまざまなお客様に対し、一人ひとりに対する接客の難しさを感じていました。</p>
                <p>そんな中、私が対応した一人のお客様との会話から、その方がホテル好きで、新しくできたホテルに来るのを楽しみにしていたと知りました。何気ない会話ではありましたが、後日、今度はご夫婦で改めてご来館いただき、「橋本さんいますか」と自分に会いに来てくれたことがとても印象的です。今では当ホテルの大切なリピーターの一人です。</p>
              </>,
            },
            {
              img: {
                src: '/assets/images/recruit/article02/img_interview03.png',
                alt: ''
              },
              title: <>
               <span>フロントでの勤務後、現在は「現場」と異なる</span>
               <span>「管理」のお仕事ですが？</span>
              </>,
              text: <>
                <p>異動当初から「私で務まるのか」と今でも考えることがあります。フロントのような、いわゆる「現場」のお仕事とは視点が異なり、さらに、エリア内すべてのホテルが担当になるので、覚えることも多く、日々勉強の毎日です。</p>
                <p>主な担当業務は、各ホテルの売上げや請求書の確認処理などの経理のお仕事、加えて、CANVASブランドのホテルで行われるイベント運営の準備などです。<br />その他にも、アルバイト採用などの人事業務や、ホテル・施設備品の管理などのお仕事も管理課がすべて担っています。</p>
              </>,
            },
            {
              img: {
                src: '/assets/images/recruit/article02/img_interview04.png',
                alt: ''
              },
              title: <>
               <span>これまでの経験が、今に活きていることは</span>
               <span>ありますか？</span>
              </>,
              text: <>
                <p>現在の管理課業務に活きているかどうかは分かりませんが、「お客様目線」はホテルで働く人間として忘れないようにしています。</p>
                <p>管理課は、現場とは違った視点での物事の見方も必要になるので、オペレーション一つ、イベント一つとっても、時には現場のスタッフと意見が食い違うときがあったりします。そんな時は、お互いに良いものを作ろうとしている結果だと思うので、最終的にお客様がどう思うかを忘れてはいけないなと思っています。</p>
                <p>現場と管理、それぞれの意見に耳を傾けて、それぞれをつなぐ架け橋のような存在になれたらいいなと思います。</p>
              </>,
            },
            {
              img: {
                src: '/assets/images/recruit/article02/img_interview05.png',
                alt: ''
              },
              title: <>
               <span>橋本さんが考える“良い加減”のサービスとは</span>
               <span>どのようなものですか？</span>
              </>,
              text: <>
                <p>シンプルですが、「一人ひとりのニーズに合った仕事をすること」です。</p>
                <p>フロントのときから変わらず、その人の考えを汲み取る姿勢を大切にすることこそが、“良い加減”だと思います。先に述べたとおり、現在の管理の仕事の延長線上にも、常にお客様がいることを意識して働いています。</p>
              </>,
            },
          ]} />
        </LWrap>
      </div>
      <section className="l_sect">
        <LWrap>
          <CSectTitle title={{ ja: <>今ある力と<br className="u_sp" />将来へ向けた想い</>, en: "My 6STARS" }} />
          <CStars
            stars={[
              {
                img: {
                  src: '/assets/images/recruit/img_stars_thinking.svg',
                  alt: '人を想う力'
                },
              },
              {
                img: {
                  src: '/assets/images/recruit/img_stars_learning.svg',
                  alt: '学ぶ力'
                },
                active: true
              },
              {
                img: {
                  src: '/assets/images/recruit/img_stars_work.svg',
                  alt: '共に働く力'
                },

              },
              {
                img: {
                  src: '/assets/images/recruit/img_stars_business.svg',
                  alt: 'ビジネスする力'
                },
              },
              {
                img: {
                  src: '/assets/images/recruit/img_stars_think.svg',
                  alt: '考え抜く力'
                },
                active: true
              },
              {
                img: {
                  src: '/assets/images/recruit/img_stars_future.svg',
                  alt: '未来をつくる力'
                },
              },
            ]}
            now={{
              title: <>学ぶ力</>,
              text: <>管理担当としてまだまだ未熟なので、本当に毎日が勉強です。周りの上司や先輩方から、管理課業務の内容だけでなく、社会人としての姿勢や話し方一つまで、身近に尊敬できる方々がいらっしゃるので、何から何まで学ばせてもらっています。</>
            }}
            future={{
              title: <>考え抜く力</>,
              text: <>
                <p>今はまだ分からないことが多く、その度に聞いて覚えての状況なので、一人で自信をもって仕事ができていません。今の学びをより深いものにして、今度は「やりたい、やってみたい」を「できた」に変えられるよう、主体的に行動し、実行していきたいと思います。</p>
                <p>近い将来、私が企画立案したイベントで、お客様はもちろん、ホテルスタッフも含めたたくさんの人に喜んでもらいたいです。</p>
              </>
            }}
          />
        </LWrap>
      </section>
      <section className="l_sect u_bgGray">
        <LWrap>
          <CSectTitle title={{
            ja: '他の社員の声',
            en: 'OTHER STAFF INTERVIEW'
          }} />
          <CLabelCard align="center" data={[
            {
              img: {
                src: '/assets/images/recruit/img_staff.png',
                alt: ''
              },
              link: {
                href: '/recruit/article01/'
              },
              labels: ['仙台ロイヤルパークホテル'],
              title: {
                dataMain: '柴 朋也',
                sub: <>料飲</>
              },
            },
            {
              img: {
                src: '/assets/images/recruit/img_staff02.png',
                alt: ''
              },
              link: {
                href: '/recruit/article03/'
              },
              labels: ['ザ ロイヤルパーク キャンバス京都二条'],
              title: {
                dataMain: '堤 香奈子',
                sub: <>フロントチーム</>
              },
            },
          ]} />
          <CBtnList data={[
            {
              iconBefore: true,
              label: '採用情報',
              link: {
                href: '/recruit/',
              },
              icon: "arrowReverse"
            }
          ]} />
        </LWrap>
      </section>
    </Layout>
  )
}

export default SubPage;

export const query = graphql`
  query ($slug: String!){
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`